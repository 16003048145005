<template>
  <div>
    <HomeSlider />
    <div
      class="mx-auto relative px-5 desktop:max-w-5xl top-20 subpixel-antialiased text-polomski-textgrey break-words"
    >
      <AboutContent />
      <div class="h-16"></div>
      <div class="text-center">
        <p
          class="font-glober-light text-4xl desktop:text-5xl text-polomski-nav"
        >
          Kontakt
        </p>
      </div>
      <ContactContent />
    </div>
    <div class="text-white h-60">a</div>
    <PageFooter />
  </div>
</template>

<script>
// @ is an alias to /src
import HomeSlider from "@/components/HomeSlider.vue";
import PageFooter from "@/components/PageFooter.vue";
import AboutContent from "@/components/AboutContent.vue";
import ContactContent from "@/components/ContactContent.vue";

export default {
  name: "HomeView",
  components: {
    HomeSlider,
    PageFooter,
    AboutContent,
    ContactContent,
  },
};
</script>
