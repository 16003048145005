<template>
  <div class="mx-auto relative px-5 break-words">
    <div class="text-center">
      <p class="font-glober-bold text-4xl desktop:text-5xl text-polomski-nav">
        Elektrotechnika Chłodnicza
      </p>
      <div class="h-3"></div>
      <p class="font-glober-light text-4xl desktop:text-5xl text-polomski-nav">
        Arkadiusz Połomski
      </p>
      <br />
      <div
        class="font-glober-light text-xl desktop:text-2xl text-polomski-textgrey"
      >
        <p>
          Jesteśmy solidną firmą działającą na rynku nieprzerwanie już od 1989
          roku.
        </p>
        <div class="h-1"></div>
        <p>Obecnie działalność prowadzi już trzecie pokolenie chłodniarzy.</p>
        <div class="h-1"></div>
        <p>
          Świadczymy profesjonalne usługi w zakresie instalacji chłodniczej,
          systemów klimatyzacji i wentylacji.
        </p>
        <div class="h-1"></div>
        <p>Wieloletnie doświadczenie to Nasz największy atut.</p>
      </div>
      <div class="h-14"></div>
    </div>
    <div
      class="inline-grid grid-cols-1 mobile:grid-cols-2 gap-10 place-content-center"
    >
      <div class="flex h-auto">
        <img
          class="h-20 pr-5 place-self-center"
          src="../assets/IMG/AboutFiles/Clock.png"
          loading="lazy"
        />
        <div class="place-self-center">
          <p class="font-glober-bold text-lg">SZYBKI CZAS REALIZACJI</p>
          <p class="font-glober-regular text-base">
            Gwarantujemy wykonanie usługi w ustalonym terminie.
          </p>
        </div>
      </div>
      <div class="flex h-auto">
        <img
          class="h-20 pr-5 place-self-center"
          src="../assets/IMG/AboutFiles/Help.png"
          loading="lazy"
        />
        <div class="place-self-center">
          <p class="font-glober-bold text-lg">
            NAPRAWY GWARANCYJNE I POGWARANCYJNE
          </p>
          <p class="font-glober-regular text-base">
            Świadczymy naprawy gwarancyjne i pogwarancyjne urządzeń.
          </p>
        </div>
      </div>
      <div class="flex h-auto">
        <img
          class="h-20 pr-5 place-self-center"
          src="../assets/IMG/AboutFiles/Box.png"
          loading="lazy"
        />
        <div class="place-self-center">
          <p class="font-glober-bold text-lg">FABRYCZNIE NOWE URZĄDZENIA</p>
          <p class="font-glober-regular text-base">
            Wszystkie oferowane przez Nas urządzenia i akcesoria są fabrycznie
            nowe z pełną gwarancją producenta.
          </p>
        </div>
      </div>
      <div class="flex h-auto">
        <img
          class="h-20 pr-5 place-self-center"
          src="../assets/IMG/AboutFiles/Safe.png"
          loading="lazy"
        />
        <div class="place-self-center">
          <p class="font-glober-bold text-lg">GWARANCJA ZADOWOLENIA</p>
          <p class="font-glober-regular text-base">
            Dołożymy wszelkich starań, abyście byli Państwo w 100% zadowoleni z
            Naszych usług.
          </p>
        </div>
      </div>
    </div>
    <div class="h-20"></div>
    <div>
      <p
        class="font-glober-light text-lg desktop:text-xl text-polomski-textgrey text-center"
      >
        Nasza firma współpracuje z najlepszymi producentami w branży chłodnictwa
        i klimatyzacji
      </p>
    </div>
    <div class="h-14"></div>
    <div class="relative">
      <Splide :options="options">
        <SplideSlide>
          <div class="h-32">
            <a href="https://www.danfoss.com/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/Danfoss.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://www.bitzer.de/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/Bitzer.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://www.kelvion.com/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/Kelvion.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://www.fujitsu-general.com/pl/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/Fujitsu.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://www.frascold.it/en">
              <img
                class="px-3 mobile:px-10 h-24"
                src="../assets/SVG/Frascold.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://www.castel.it/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/Castel.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://www.mhi.info.pl/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/MitsubishiHeavyIndustries.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://pl.mitsubishielectric.com/pl/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/MitsubishiElectric.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a
              href="https://www.modinecoolers.com/products/eco-heat-transfer-coolers/"
            >
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/ECO.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://kaisai.com/pl/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/Kaisai.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://zymetric.pl/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/Midea.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://paneltech.pl/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/Paneltech.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a href="https://www.rhoss.com/">
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/Rhoss.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a>
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/SchneiderElectric.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
        <SplideSlide>
          <div class="h-32">
            <a>
              <img
                class="px-3 mobile:px-10 h-20"
                src="../assets/SVG/ZiehlAbegg.svg"
                loading="lazy"
              />
            </a>
          </div>
        </SplideSlide>
      </Splide>
    </div>
    <div class="h-16"></div>
    <div>
      <div
        class="font-glober-regular text-lg px-5 p-3 border-2 border-polomski-nav rounded-full text-polomski-nav text-center"
      >
        <router-link to="/offer">ZAPOZNAJ SIĘ Z PEŁNĄ OFERTĄ</router-link>
      </div>
    </div>
    <div class="h-16"></div>
    <div class="text-center">
      <p class="font-glober-light text-4xl desktop:text-5xl text-polomski-nav">
        Realizacje
      </p>
      <br />
      <p
        class="font-glober-light text-lg desktop:text-xl text-polomski-textgrey"
      >
        Przedstawiamy Państwu kilka przykładowych zdjęć z realizacji Naszych
        projektów
      </p>
    </div>
    <div class="h-12"></div>
    <div
      class="mx-auto relative inset-0 inline-grid grid-cols-2 mobile:grid-cols-3 gap-5 items-center"
    >
      <a href="Portfolio/83.webp" data-lightbox="AboutGallery">
        <img
          loading="lazy"
          class="box-border w-80 h-48 border-8 object-cover lazyload"
          data-src="Portfolio/83thumbnail.webp"
        />
      </a>
      <a href="Portfolio/82.jpg" data-lightbox="AboutGallery">
        <img
          loading="lazy"
          class="box-border w-80 h-48 border-8 object-cover lazyload"
          data-src="Portfolio/82thumbnail.jpg"
        />
      </a>
      <a href="Portfolio/84.webp" data-lightbox="AboutGallery">
        <img
          loading="lazy"
          class="box-border w-80 h-48 border-8 object-cover lazyload"
          data-src="Portfolio/84thumbnail.webp"
        />
      </a>
      <a href="Portfolio/90.webp" data-lightbox="AboutGallery">
        <img
          loading="lazy"
          class="box-border w-80 h-48 border-8 object-cover lazyload"
          data-src="Portfolio/90thumbnail.webp"
        />
      </a>
      <a href="Portfolio/85.webp" data-lightbox="AboutGallery">
        <img
          loading="lazy"
          class="box-border w-80 h-48 border-8 object-cover lazyload"
          data-src="Portfolio/85thumbnail.webp"
        />
      </a>
      <a href="Portfolio/74.jpg" data-lightbox="AboutGallery">
        <img
          loading="lazy"
          class="box-border w-80 h-48 border-8 object-cover lazyload"
          data-src="Portfolio/74thumbnail.jpg"
        />
      </a>
    </div>
    <div class="h-12"></div>
    <div>
      <div
        class="font-glober-regular text-lg px-5 p-3 border-2 border-polomski-nav rounded-full text-polomski-nav text-center"
      >
        <router-link to="/portfolio">
          ZOBACZ WIĘCEJ NASZYCH REALIZACJI
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Splide,
    SplideSlide,
  },
  setup() {
    const options = {
      type: "loop",
      perPage: 3,
      autoplay: true,
      interval: 5000,
      speed: 250,
      arrows: false,
      pauseOnHover: false,
      pauseOnFocus: false,
    };
    return { options };
  },
});
</script>
