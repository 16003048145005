<template>
  <div
    class="mx-auto relative px-5 desktop:max-w-5xl top-20 subpixel-antialiased text-polomski-textgrey break-words"
  >
    <div class="flex flex-col desktop:flex-row gap-20">
      <div class="text-center desktop:text-left">
        <p class="font-glober-light text-3xl">
          Elektrotechnika Chłodnicza
          <span class="inline-block">Arkadiusz Połomski</span>
        </p>
        <br />
        <p class="font-glober-regular text-lg">
          Zapraszamy Państwa do kontaktu z Naszą firmą.
          <span class="inline-block">
            Zachęcamy do kontaktu telefonicznego lub mailowego.
          </span>
        </p>
        <br />
        <p class="font-glober-regular text-base">
          ul. Gen. Władysława Sikorskiego 47b, 63-500, Ostrzeszów;
        </p>
        <br />
        <p class="font-glober-regular text-base">tel. +48 609 098 129;</p>
        <p class="font-glober-regular text-base">
          email. arkadiusz.polomski@onet.eu,
          <span class="inline-block">web. www.polomski-chlodnictwo.pl;</span>
        </p>
        <a href="https://www.facebook.com/polomskichlodnictwo/" target="_blank">
          <p class="font-glober-regular text-base">
            facebook: www.facebook.com/polomskichlodnictwo
          </p>
        </a>
      </div>
      <div class="place-self-center">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2488.8072538754636!2d17.933590715653057!3d51.4065961796186!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471aa41d141af23b%3A0x59e3f080b6c5f63a!2sPo%C5%82omski%20Arkadiusz.%20Ch%C5%82odnictwo%2C%20klimatyzacja!5e0!3m2!1sen!2shu!4v1645049558178!5m2!1sen!2shu"
          width="350"
          height="500"
          style="border: 0"
          loading="lazy"
        ></iframe>
      </div>
    </div>
  </div>
</template>
