<template>
  <div
    class="mx-auto px-3 desktop:px-5 sticky top-0 z-50 min-w-full bg-polomski-nav"
  >
    <Menu v-slot="navslot">
      <nav>
        <div class="relative h-20 desktop:h-24">
          <div class="absolute flex left-0 inset-y-0 items-center">
            <router-link to="/">
              <img
                class="h-16 desktop:h-20 block"
                src="./assets/SVG/logo-white.svg"
              />
            </router-link>
          </div>
          <div
            class="absolute flex right-0 inset-y-0 items-center text-white font-glober-regular text-sm subpixel-antialiased"
          >
            <div class="flex gap-5">
              <div class="flex-auto hidden desktop:block">
                <router-link to="/">HOME</router-link>
              </div>
              <div class="contents">
                <div class="flex-auto hidden mobile:block">
                  <router-link to="/about">O FIRMIE</router-link>
                </div>
                <div class="flex-auto hidden mobile:block">
                  <router-link to="/offer">NASZA OFERTA</router-link>
                </div>
                <div class="flex-auto hidden mobile:block">
                  <router-link to="/portfolio">REALIZACJE</router-link>
                </div>
                <div class="flex-auto hidden desktop:block">
                  <router-link to="/certificates">CERTYFIKATY</router-link>
                </div>
                <div class="flex-auto hidden desktop:block">
                  <router-link to="/references">REFERENCJE</router-link>
                </div>
                <div class="flex-auto hidden mini:block">
                  <router-link to="/contact">KONTAKT</router-link>
                </div>
              </div>
              <div class="flex-auto desktop:hidden">
                <MenuButton class="outline-none">MENU</MenuButton>
              </div>
            </div>
          </div>
        </div>
      </nav>
      <div
        v-show="navslot"
        class="desktop:hidden flex justify-center font-glober-regular text-sm subpixel-antialiased outline-none text-white"
      >
        <MenuItems class="outline-none">
          <div
            class="mx-auto h-44 menu530:h-28 menu-700:h-12 inset-y-0 inline-grid grid-cols-3 menu530:grid-cols-5 menu700:grid-cols-7 gap-5"
          >
            <div class="mx-auto">
              <MenuItem class="outline-none">
                <div>
                  <router-link to="/">HOME</router-link>
                </div>
              </MenuItem>
            </div>
            <div class="mx-auto">
              <MenuItem class="outline-none">
                <div>
                  <router-link to="/about">O FIRMIE</router-link>
                </div>
              </MenuItem>
            </div>
            <div class="mx-auto">
              <MenuItem class="outline-none text-center">
                <div>
                  <router-link to="/offer">NASZA OFERTA</router-link>
                </div>
              </MenuItem>
            </div>
            <div class="mx-auto">
              <MenuItem class="outline-none">
                <div>
                  <router-link to="/portfolio">REALIZACJE</router-link>
                </div>
              </MenuItem>
            </div>
            <div class="mx-auto">
              <MenuItem class="outline-none">
                <div>
                  <router-link to="/certificates">CERTYFIKATY</router-link>
                </div>
              </MenuItem>
            </div>
            <div class="mx-auto">
              <MenuItem class="outline-none">
                <div>
                  <router-link to="/references">REFERENCJE</router-link>
                </div>
              </MenuItem>
            </div>
            <div class="mx-auto">
              <MenuItem class="outline-none">
                <div>
                  <router-link to="/contact">KONTAKT</router-link>
                </div>
              </MenuItem>
            </div>
          </div>
        </MenuItems>
      </div>
    </Menu>
  </div>
  <router-view />
</template>

<script>
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";

export default {
  components: {
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
};
</script>
