<template>
  <footer class="mx-auto relative min-w-full">
    <div
      class="text-white absolute px-5 bottom-0 gap-3 inline-flex right-0 font-glober-regular text-xs bg-polomski-undernav subpixel-antialiased rounded"
    >
      <div class="hidden footer:block">
        <a href="/home"> HOME |</a>
        <a href="/about"> O FIRMIE |</a>
        <a href="/offer"> NASZA OFERTA |</a>
        <a href="/portfolio"> REALIZACJE |</a>
        <a href="/certificates"> CERTYFIKATY |</a>
        <a href="/references"> REFERENCJE |</a>
        <a href="/contact"> KONTAKT </a>
        <a href="/heat-pumps" class="hidden">| POMPY CIEPŁA</a>
      </div>
      <p class="text-center">
        WSZYSTKIE PRAWA ZASTRZEŻONE DLA ELEKTROTECHNIKA CHŁODNICZA ARKADIUSZ
        POŁOMSKI &#169;{{ new Date().getFullYear() }}
      </p>
    </div>
  </footer>
</template>
