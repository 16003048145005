import { createApp } from "vue";
import App from "./app.vue";
import router from "./router";
import "./index.css";
import "@splidejs/splide/dist/css/splide.min.css";
import "lightbox2/dist/css/lightbox.css";
import "lightbox2/dist/js/lightbox-plus-jquery";
import "lazysizes";
import "lazysizes/plugins/parent-fit/ls.parent-fit";

createApp(App).use(router).mount("#app");
