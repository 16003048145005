<template>
  <div class="relative">
    <Splide :options="options">
      <SplideSlide>
        <div>
          <img
            loading="lazy"
            src="../assets/IMG/HomeSlider/HomeSlider3.webp"
            alt="Montaż urządzeń chłodniczych i mroźniczych"
            class="object-cover object-center w-full h-screen lazyload"
          />
          <div
            class="absolute flex flex-col inset-0 items-center justify-center text-center px-5 text-white subpixel-antialiased"
          >
            <div class="text-4xl font-glober-bold text-shadow-lg pb-2">
              Montaż urządzeń chłodniczych i mroźniczych
            </div>
            <div class="text-xl font-glober-regular text-shadow-lg pb-3">
              Oferujemy projekt, dobór urządzeń i montaż kompletnych komór
              chłodniczych oraz mroźniczych.
            </div>
            <div
              class="font-glober-regular text-shadow-lg text-sm items-center justify-center px-5 py-3 border border-white rounded-full text-white"
            >
              <router-link to="/offer">DOWIEDZ SIĘ WIĘCEJ</router-link>
            </div>
          </div>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div>
          <!--<img
            loading="lazy"
            src="../assets/IMG/HomeSlider/"
            alt="Pompy ciepła"
            class="object-cover object-center w-full h-screen lazyload"
          /> -->
          <picture class="object-cover object-center w-full h-screen lazyload">
            <source
              media="(min-width: 900px)"
              srcset="../assets/IMG/HomeSlider/double.webp"
              class="object-cover object-center w-full h-screen lazyload"
              alt="Pompy ciepła"
            />
            <img
              src="../assets/IMG/HomeSlider/single.webp"
              class="object-cover object-center w-full h-screen lazyload"
              alt="Pompy ciepła"
            />
          </picture>
          <div
            class="absolute flex flex-col inset-0 items-center justify-center text-center px-5 text-white subpixel-antialiased"
          >
            <div
              class="text-4xl font-glober-bold text-shadow-lg shadow-polomski-textgrey pb-2"
            >
              Dobór i montaż pomp ciepła
            </div>
            <div
              class="text-xl font-glober-regular text-shadow-lg shadow-polomski-textgrey pb-3"
            >
              Oferujemy dobór i kompleksowy montaż pomp ciepła
              <br />
              powietrze-woda oraz powietrze-powietrze.
            </div>
            <div
              class="font-glober-regular text-shadow-lg text-sm items-center justify-center px-5 py-3 border border-white rounded-full text-white"
            >
              <router-link to="/offer">DOWIEDZ SIĘ WIĘCEJ</router-link>
            </div>
          </div>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div>
          <img
            loading="lazy"
            src="../assets/IMG/HomeSlider/HomeSlider2.webp"
            alt="Klimatyzacja i wentylacja"
            class="object-cover object-center w-full h-screen lazyload"
          />
          <div
            class="absolute flex flex-col inset-0 items-center justify-center text-center px-5 text-white subpixel-antialiased"
          >
            <div class="text-4xl font-glober-bold text-shadow-lg pb-2">
              Systemy wentylacji i klimatyzacji pomieszczeń
            </div>
            <div class="text-xl font-glober-regular text-shadow-lg pb-3">
              Oferujemy szeroki zakres usług związanych z doborem, montażem
              <br class="hidden desktop:block" />
              oraz serwisem urządzeń klimatyzacyjnych.
            </div>
            <div
              class="font-glober-regular text-shadow-lg text-sm items-center justify-center px-5 py-3 border border-white rounded-full text-white"
            >
              <router-link to="/offer">DOWIEDZ SIĘ WIĘCEJ</router-link>
            </div>
          </div>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div>
          <img
            loading="lazy"
            src="../assets/IMG/HomeSlider/HomeSlider1.webp"
            alt="Montaż urządzeń chłodniczych"
            class="object-cover object-center w-full h-screen lazyload"
          />
          <div
            class="absolute flex flex-col inset-0 items-center justify-center text-center px-5 text-white subpixel-antialiased"
          >
            <div class="text-4xl font-glober-bold text-shadow-lg pb-2">
              Montaż urządzeń chłodniczych i mroźniczych
            </div>
            <div class="text-xl font-glober-regular text-shadow-lg pb-3">
              Oferujemy projekt, dobór urządzeń i montaż kompletnych komór
              chłodniczych oraz mroźniczych.
            </div>
            <div
              class="font-glober-regular text-shadow-lg text-sm items-center justify-center px-5 py-3 border border-white rounded-full text-white"
            >
              <router-link to="/offer">DOWIEDZ SIĘ WIĘCEJ</router-link>
            </div>
          </div>
        </div>
      </SplideSlide>
      <SplideSlide>
        <div>
          <img
            loading="lazy"
            src="../assets/IMG/HomeSlider/HomeSlider4.webp"
            alt="Montaż urządzeń chłodniczych"
            class="object-cover object-center w-full h-screen lazyload"
          />
          <div
            class="absolute flex flex-col inset-0 items-center justify-center text-center px-5 text-white subpixel-antialiased"
          >
            <div class="text-4xl font-glober-bold text-shadow-lg pb-2">
              Montaż urządzeń chłodniczych i mroźniczych
            </div>
            <div class="text-xl font-glober-regular text-shadow-lg pb-3">
              Oferujemy projekt, dobór urządzeń i montaż kompletnych komór
              chłodniczych oraz mroźniczych.
            </div>
            <div
              class="font-glober-regular text-shadow-lg text-sm items-center justify-center px-5 py-3 border border-white rounded-full text-white"
            >
              <router-link to="/offer">DOWIEDZ SIĘ WIĘCEJ</router-link>
            </div>
          </div>
        </div>
      </SplideSlide>
    </Splide>
  </div>
</template>

<script>
import { Splide, SplideSlide } from "@splidejs/vue-splide";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    Splide,
    SplideSlide,
  },
  setup() {
    const options = {
      type: "loop",
      autoplay: true,
      interval: 5000,
      speed: 250,
      arrows: false,
      pauseOnHover: false,
      pauseOnFocus: false,
    };
    return { options };
  },
});
</script>
